import React, { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import Clock from "./shared/Clock";

export default function Footer(): ReactElement {
  return (
    <footer className="footer fixed-bottom" style={{ padding: "0 1rem" }}>
      <div style={{ position: "relative" }}>
        <Clock />
        <div
          style={{ position: "absolute", top: "0", right: "0", zIndex: 100 }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="site-switcher"
              style={{ padding: "2px" }}
            >
              Select Site
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-important">
              <Dropdown.Item href="https://obsmoana.com">
                Observatoire Moana
              </Dropdown.Item>
              <Dropdown.Item href="https://elsauce.obsmoana.com">
                Observatorio El Sauce
              </Dropdown.Item>
              <Dropdown.Item href="https://sso.obsmoana.com">
                Moana Observatory SSO
              </Dropdown.Item>
              <Dropdown.Item href="https://ee1.obsmoana.com">
                Observatorio Moana E-Eye
              </Dropdown.Item>
              {/* <Dropdown.Item href="https://ee2.obsmoana.com">
                Observatorio Moana E-Eye 2
              </Dropdown.Item>
              <Dropdown.Item href="https://nms.obsmoana.com">
                Observatory Moana NMS
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </footer>
  );
}
